@media only screen and (max-width: 780px) {
	.mobile-menu {
	  display:block;
	  width:40px;
	  height:40px;
	  text-align: center;
	  right: 0;
	  top: 0;
	  z-index: 384;
	  padding: 5px;
	  span {
	    position:relative;
	    display:block;
	    margin: 12px 0px;
	    &:after,
			&:before {
		    content:"";
		    position:absolute;
		    left: 0;
			}
		}
		span,
		span:after,
		span:before {
	    height:4px;
	    width:25px;
	    text-indent:-9999px;
	    background-color:$white;
	    transition:all .25s ease-out;
		}
		span:before {
	    top:-8px;
		}
		span:after {
		  top:8px;
		}
	}
	
	.open {
		.mobile-menu{
			border:none;
			span:before,
			span:after{
			  top:0;
			}
			span {
			  background-color:rgba(0,0,0,0);
			  &:after{
				  -webkit-transform: translateY(0) rotate(-45deg);
				  transform: translateY(0) rotate(-45deg);
				}
				&:before{
				  -webkit-transform: translateY(0) rotate(45deg);
				  transform: translateY(0) rotate(45deg);
				}
			}
		}
	}
	
	header.header {
  	.region-header {
    	display: none;
    	position: absolute;
    	background: $black;
    	top: 100%;
    	right: 0;
    	left: 0;
    	z-index: 250;
  	}
  	.block-menu ul.menu {
    	display: block;
    	
    	li {
      	margin: 0;
      	
      	a {
        	display: block;
        	padding: 10px;
        	&.active-trail {
            background: $red;
            color: $black;
        	}
      	}
    	}
  	}
  }
	
	.block-search{
		display:none;
	}
	
	.region-bottom {
  	.container {
    	display: block;
    	
    	&:before {
      	height: 50px;
      	width: 100%;
      	background-position: center top;
    	}
  	}
  	.block,
  	#block-views-contact-block {
    	width: 100%;
    	margin-bottom: 15px;
  	}
  }
  
  .block-taxonomy-menu-block > ul {
    display: block;
    li {
      width: 100%;
    }
  }
  .page-node-3,
  .page-node-24 {
    .field-name-field-video,
    .field-name-body {
      width: 100%;
      float: none;
      .field-item {
        padding-left: 0;
      }
    }
  }
  #block-views-contact-block-2 {
    width: 100%;
    height: auto;
    padding: 20px 0;
    margin-bottom: 50px;
    float: none;
    margin-top: 0;
    
    .view-contact .views-row {
      display: block;
    }
  }
  .page-node-4 #block-views-contact-block-1 .views-row .views-field, .page-taxonomy-term-5 #block-views-contact-block-1 .views-row .views-field {
    width: 100%;
  }
  .view-actualit-s .view-content .views-row {
    width: 100%;
    margin-bottom: 15px;
    
    img {
      width: 100%;
    }
  }
  
  .page-node-30 {
    .block-webform {
      width: 100%;
      float: none;
      margin: 0;
      margin-bottom: 15px;
      margin-top: 30px;
      
      .form-submit {
        margin-bottom: 0;
      }
    }
    #block-views-contact-block-3 {
      width: 100%;
      margin:0;
      float: none;
    }
  }
  
  .section-atelier .view-atelier-taxonomy .views-row {
    .views-field-field-images,
    .views-field-body {
      width: 100%;
      float: none;
      margin: 10px 0;
    }  
    .views-field-body {
      .field-content {
        padding: 0;
      }
    }  
  }
  .page-taxonomy-term.page-taxonomy-term-6 .views-field-description, .page-taxonomy-term.page-taxonomy-term-6 .taxonomy-term-description, .page-taxonomy-term.page-taxonomy-term-17 .views-field-description, .page-taxonomy-term.page-taxonomy-term-17 .taxonomy-term-description {
    max-width: 90%;
  }
  
  .page-taxonomy-term-18 .view-display-id-page_3 > .view-header,
  .page-taxonomy-term-18 .view-display-id-page_3 > .view-content,
  .page-taxonomy-term-16 .block-views > .view-taxonomy .view-header, .page-taxonomy-term-29 .block-views > .view-taxonomy .view-header,
  .page-taxonomy-term-16 .block-views > .view-taxonomy > .view-content, .page-taxonomy-term-29 .block-views > .view-taxonomy > .view-content,
  .page-taxonomy-term-17 .view-display-id-page_4 > .view-header,
  .page-taxonomy-term-17 .view-display-id-page_4 > .view-content,
  .page-taxonomy-term-17 #block-views-contact-block-1,
  .page-taxonomy-term-15 .view-display-id-page_3 > .view-header,
  .page-taxonomy-term-15 .view-display-id-page_3 > .view-content {
    width: 100%;
    float: none;
    margin-bottom: 30px;
    
    .views-row {
      padding-left: 0;
    }
  }
  .page-taxonomy-term-16 .block-views > .view-taxonomy, .page-taxonomy-term-29 .block-views > .view-taxonomy {
    display: block;
  }
  
  .page-taxonomy-term-17 #block-views-contact-block-1 h2, .page-taxonomy-term-17 #block-views-contact-block-1 .view-contact {
    padding-left: 0;
  }
  
  #block-views-contact-block-1,
  #block-views-taxonomy-block-2 {
    width: 100%;
    float: none;
    margin-bottom: 20px;
  }
}