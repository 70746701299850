@import 'variables';
@import 'libraries/font-awesome/font-awesome';

*:focus{
	outline: none !important;
	box-shadow: none !important;
}

body {
	background: url('../images-source/bg-body.png');
	font-family: $museo;
	font-size: 15px;
}
h2{
	font-size:22px;
}
p {
	margin: 0;
	margin-bottom: 0.5em;
}
ul{
	margin: 0;
	padding-left: 20px;
}
.block {
	position: relative;
	margin: 0;
}
::-webkit-input-placeholder {
   color: $white;
}

:-moz-placeholder { /* Firefox 18- */
   color: $white;
}

::-moz-placeholder {  /* Firefox 19+ */
   color: $white;
}

:-ms-input-placeholder {
   color: $white;
}
ul.slick-slider{
	padding:0;
}
.slick-prev, .slick-next{
	width:32px;
	height:32px;
	z-index:120;
	&:before{
		font-size:35px;
	}
}
.slick-prev{
	left:20px;
}
.slick-next{
	right:20px;
}
.page-node {
  article {
    .slick {
      float: left;
      width: 75%;
      position: relative;
    }
    .field-name-body {
      width: 25%;
      float: right;
      font-size: 12px;
      line-height: 1.6;
      color: $white;
    }
  }
}
.container,
#block-views-home-block,
#block-views-home-block-1,
#block-views-home-block-3,
.not-front main {
	width: 90%;
	margin: 0 auto;
	max-width: 1110px;
}
.views-field-field-fichier{
	.file{
		img{
			display:none;
		}
		a{
			display: inline-block;
			border-radius: 2px;
			padding: 1em 1.5em;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			font-weight: 700;
			border: none;
			appearance: none;
			color: $white;
			transition: background linear .2s;
			background: $red;
			text-decoration:none;
			margin-bottom:40px;
			&:hover, &:focus {
				background: lighten($red, 10%);
				color: $white;
			}
		}
	}
}
.views-field-body{
	color:$white;
}
.button {
	display: inline-block;
	border-radius: 2px;
	padding: 1em 1.5em;
	text-transform: uppercase;
	letter-spacing: 1px;
	font-size: 12px;
	font-weight: 700;
	border: none;
	appearance: none;
	color: $white;
	transition: background linear .2s;
	margin: 30px 5px 0;
	&.button-black {
		background: $black;
		&:hover, &:focus {
			background: lighten($black, 10%);
			color: $white;
		}
	}
	&.button-red {
		background: $red;
		&:hover, &:focus {
			background: lighten($red, 10%);
			color: $white;
		}
	}
}

header.header {
	background: $black;
	position: relative;
	.container{
		display: flex;
    justify-content: space-between;
    align-items: center;
	}
	.header__logo {
		float: none;
		margin: 0;
	}
	.header__site-slogan {
		text-align: right;
	}
	.header__name-and-slogan {
		color: $white;
		font-size: 8px;
		float: none;
		display: inline-block;
		position: relative;
		vertical-align: middle;
		padding:2em 0;
	}
	.region-header{
		display: flex;
    align-items: center;
	}
	.block-menu {
		ul.menu {
			display: flex;
			margin: 0;
			padding: 0;
			li {
				list-style: none;
				margin:0 10px;
				a {
					color:$white;
					text-decoration: none;
					transition: color linear .2s;
					&:hover, &:focus {
						color: $red;
					}
					&.active,
					&.active-trail {
						color: $red;
						font-weight: 900;
					}
				}
				&.first {
  				a {
    				font-size: 0;
    				&:before {
      				font-size: 16px;
    				}
  				}
				}
			}
		}
	}
	.block-views {
  	.views-exposed-widgets {
    	display: flex;
			margin-left: 10px;
			margin-bottom: 0;

			#edit-search-api-views-fulltext-wrapper {
  			padding: 0;

  			label {
    			display: none;
  			}

  			input {
    			background: $gray;
  				color: lighten($gray, 30%);
  				border: 0;
  				height: 88px;
  				padding:0 1em;
  				width: 160px;
  			}

			}

			.views-submit-button {
  			padding: 0;
  			.form-submit {
  			  margin: 0;
  			  background: $gray;
  				color: lighten($gray, 30%);
  				border: 0;
  				height: 88px;
  				padding:0 1em;
        }
			}
  	}
	}
	form{
		.container-inline {
			display: flex;
			margin-left: 10px;
			.form-item {
				margin: 0;
			}
			input {
				background: $gray;
				color: lighten($gray, 30%);
				border: 0;
				height: 88px;
				padding:0 1em;
			}
		}
	}
	#block-menu-menu-eshop {
		.menu {
			li {
				margin: 0;
				a {
					line-height: 88px;
					height: 88px;
					background: $red;
					text-transform: uppercase;
					display: block;
					padding: 0 20px;
					&:hover,
					&:focus {
						background: darken($red, 15%);
						color:$white;
					}
				}
			}
		}
	}
}

.easy-breadcrumb {
	color: $white;
	padding: 5px 0;
	font-size:12px;
	a {
		color: $white;
		font-size:12px;
	}
}

article.node-1 {
	padding: 7% 0;
	.field-name-body {
		background: url('../images-source/bg-content.png');
		width: 80%;
		max-width: 540px;
		margin: 0 auto;
		text-align: center;
		padding: 2em;
		float: none;
		h2 {
			margin: 0;
			color: $red;
			font-size: 30px;
			font-weight: 500;
			margin-bottom: 15px;
			strong {
				color: $white;
				font-weight: 900;
			}
		}
		p {
			margin-bottom: 0;
			color: $white;
		}
	}
}
#block-views-home-block-3 {
	.view-home.view-display-id-block_3 {
		padding-top: 2%;
    padding-left: 4%;
		> .view-header {
			width: 50%;
		}
		> .views-content {
			background: $black;
			color: $white;
			width: 50%;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 30;
			.views-field-body {
				.field-content {
					padding: 30px;
					h2{
						color: $red;
				    text-transform: uppercase;
				    font-weight: 900;
				    font-size: 30px;
				    letter-spacing: 1px;
				    padding-left: 15px;
				    position: relative;
				    &:after {
					    display: block;
					    width: 46px;
					    height: 5px;
					    content: "";
					    background: $lightgray;
					    position: absolute;
					    left: 0;
					    margin: 10px 0;
				    }
					}
					strong {
						color: $red;
						display: block;
						margin-top:15px;
					}
					p{
						margin:0;
						text-align: left;
					}
					p{
						a{
							color:$white;
						}
					}
					.info-pratique{
						padding-left: 15px;
						text-align:center;
					}
				}
			}
		}
	}
}

#block-block-1 {
	background: $red;
	margin-top: 100px;
	text-align: center;
	padding: 0.6em;
	clear:both;
	p {
		margin: 0;
		color: $white;
		font-weight: 300;
		a {
			color: $white;
			font-weight: 700;
			text-decoration: none;
			border-bottom: 1px solid $white;
		}
	}
}

.region-bottom {
	background: $black;
	color: $white;
	font-weight: 300;

	a {
  	color: $red;
	}
	.container {
		display:flex;
		padding: 30px 0;
		justify-content: space-between;
		&:before{
			display:block;
			width: 16%;
			content:"";
			background:url('../images-source/logo.png') left center no-repeat;
			/* ECRAN RETINA */
			@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
			only screen and (-o-max-device-pixel-ratio: 3/2),
			only screen and (max--moz-device-pixel-ratio: 1.5),
			only screen and (max-device-pixel-ratio: 1.5) {
				background:url('../images-source/logo.png') left center no-repeat;
			}
			@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
			only screen and (-o-min-device-pixel-ratio: 3/2),
			only screen and (min--moz-device-pixel-ratio: 1.5),
			only screen and (min-device-pixel-ratio: 1.5) {
				background:url('../images-source/logo@2x.png') left center no-repeat;
				background-size:135px 46px;
			}

		}
	}
	#block-views-contact-block{
		width:18%;
	}
	#block-menu-menu-nos-produits{
		ul.menu{
			column-count:2;
			padding:0;
			li{
				&:before{
					display:inline-block;
					content:"•";
					margin-right:10px;
				}
				a{
					color:$white;
					text-decoration:none;
					&.active{
						color:$white;
					}
				}
			}
		}
	}
	.block{
		width:24%;
	}
	h2 {
		margin:0;
		font-size:15px;
		padding-left: 10px;
		position: relative;
		margin-bottom: 1em;
		&:after {
			content: "";
			display: block;
			background: $red;
			position: absolute;
			width: 30px;
			height: 3px;
			left: 0;
		}
	}
	.views-field-title {
		color: $red;
		font-weight: 700;
	}
}
.front{
	.easy-breadcrumb {
		display:none;
	}
}
.not-front {
	h1 {
		color: $red;
		font-size: 40px;
		font-weight: 900;
		text-align: center;
	}
}
// TAXONOMY BLOCK
.block-taxonomy-menu-block {
	& > ul{
		margin:0;
		padding:0;
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		li{
			list-style:none;
			width:50%;
			text-align: center;
			padding:100px 0;
			position: relative;
			//atelier
			&.term-4{
				background: url('../images-source/bg-tag-4.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-5{
				background: url('../images-source/bg-tag-5.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-6{
				background: url('../images-source/bg-tag-6.jpg') center center no-repeat;
				background-size: cover;
			}
      &.term-7{
				background: url('../images-source/bg-tag-7.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-8{
				background: url('../images-source/bg-tag-8.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-9{
				background: url('../images-source/bg-tag-9.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-10{
				background: url('../images-source/bg-tag-10.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-11{
				background: url('../images-source/bg-tag-11.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-12{
				background: url('../images-source/bg-tag-12.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-13{
				background: url('../images-source/bg-tag-13.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-14	{
				background: url('../images-source/bg-tag-14.jpg') center center no-repeat;
				background-size: cover;
			}

			// magasin
			&.term-15{
				background: url('../images-source/bg-tag-15.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-16{
				background: url('../images-source/bg-tag-16.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-17{
				background: url('../images-source/bg-tag-17.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-18{
				background: url('../images-source/bg-tag-18-ok.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-22{
				background: url('../images-source/bg-tag-22.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-23{
				background: url('../images-source/bg-tag-23.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-24{
				background: url('../images-source/bg-tag-24.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-25{
				background: url('../images-source/bg-tag-25.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-26{
				background: url('../images-source/bg-tag-26.jpg') center center no-repeat;
				background-size: cover;
			}
      &.term-27{
				background: url('../images-source/bg-tag-27.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-28{
				background: url('../images-source/bg-tag-28.png') center center no-repeat;
				background-size: cover;
			}
			&.term-29{
				background: url('../images-source/bg-tag-29.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-30{
				background: url('../images-source/bg-tag-30.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-31{
				background: url('../images-source/bg-tag-31.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-33{
				background: url('../images-source/bg-tag-33.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-34{
				background: url('../images-source/bg-tag-34.jpg') center center no-repeat;
				background-size: cover;
			}
			&.term-35{
				background: url('../images-source/bg-tag-35.jpg') center center no-repeat;
				background-size: cover;
			}
			&:before{
				z-index: 40;
				content:"";
				display:block;
				position: absolute;
				top:0;
				left:0;
				right:0;
				bottom:0;
				opacity:0;
				transition: opacity linear .2s;
			}
			&:hover{
				&:before{
					background: -moz-linear-gradient(45deg,  rgba(209,16,38,0) 0%, rgba(209,16,38,0) 23%, rgba(209,16,38,1) 100%);
					background: -webkit-linear-gradient(45deg,  rgba(209,16,38,0) 0%,rgba(209,16,38,0) 23%,rgba(209,16,38,1) 100%);
					background: linear-gradient(45deg,  rgba(209,16,38,0) 0%,rgba(209,16,38,0) 23%,rgba(209,16,38,1) 100%);
					filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00d11026', endColorstr='#d11026',GradientType=1 );
					opacity: 1;
				}
			}
			a{
				position: relative;
				z-index: 60;
				display: inline-block;
				border-radius: 2px;
				padding: 1em 1.5em;
				text-transform: uppercase;
				letter-spacing: 1px;
				font-size: 12px;
				font-weight: 700;
				border: none;
				appearance: none;
				color: $white;
				transition: background linear .2s;
				background: $red;
				text-decoration: none;
				&:hover, &:focus {
					background: lighten($red, 10%);
					color: $white;
				}
			}
		}
	}
}

// A propos
.page-node-24{
	article .field-name-body{
		width:35%;
		float:left;
		margin-bottom: 30px;
		.field-item{
			padding-left:7%;
		}
		h2{
			color:$red;
			text-transform: uppercase;
			font-weight: 900;
			margin:0;
			margin-top:15px;
			&:before{
				margin-bottom:-6px;
				display:inline-block;
				content:"";
				margin-right:10px;
				width:23px;
				height:32px;
				background: url('../images-source/title-point.png') no-repeat;
				/* ECRAN RETINA */
				@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
				only screen and (-o-max-device-pixel-ratio: 3/2),
				only screen and (max--moz-device-pixel-ratio: 1.5),
				only screen and (max-device-pixel-ratio: 1.5) {
					background: url('../images-source/title-point.png') no-repeat;
				}
				@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
				only screen and (-o-min-device-pixel-ratio: 3/2),
				only screen and (min--moz-device-pixel-ratio: 1.5),
				only screen and (min-device-pixel-ratio: 1.5) {
					background: url('../images-source/title-point@2x.png') no-repeat;
					background-size:23px 32px;
				}

			}
		}
		p{
			color:$white;
			font-size:12px;
			font-weight:300;
		}
	}
}

#block-views-base-block {
	width:65%;
	float:left;
}

.player{
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	padding-top: 25px;
	height: 0;
	iframe{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

#block-views-equipe-block{
	clear:both;
	margin-top:20px;
	.block__title{
		color:$red;
		text-transform:uppercase;
		font-size:22px;
		font-weight:900;
		margin-bottom:30px;
	}
	.view-equipe{
		.view-content{
			display:flex;
			flex-wrap: wrap;
			align-content: space-between;
			.views-row{
				width:23%;
				text-align:center;
			}
		}
		.views-field-title{
			font-size:14px;
			font-weight:900;
			text-transform: uppercase;
			color:$red;
			line-height:1.4;
			margin-top:20px;
		}
		.views-field-field-fonction{
			font-size:12px;
			font-weight:700;
			text-transform: uppercase;
			color:$white;
			line-height:1.4;
		}
		.views-field-field-e-mail{
			line-height:1.4;
			a{
				color:$white;
				text-decoration: none;
				font-size:12px;
				font-weight:300;
			}
		}
	}
}

// Atelier
.page-node-3{
	article{
		margin-bottom: 45px;
	}
	.field-name-field-video {
		width:65%;
		float:left;
		.player{
			position: relative;
			padding-bottom: 56.25%; /* 16:9 */
			padding-top: 25px;
			height: 0;
			iframe{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
	}
	.field-name-body{
		width:35%;
		float:left;
		.field-item{
			padding-left:7%;
		}
		h2{
			font-weight:900;
			color:$red;
			text-transform: uppercase;
			margin:10px 0 15px;
		}
		p{
			color:$white;
			font-size:12px;
			font-weight:300;
		}
	}
}
.views-field-city a {
	color: $red;
}

// Block Contact
#block-views-contact-block-3 {
	background:$black;
	color:$white;
	padding: 2em 1em;
	width: 40%;
	float: right;
    margin-top: 109px;
	.view-contact{
	  padding: 0 7%;
	  width:100%;
		align-items: center;
		font-size:13px;
		.views-row {
			.views-field{
				display:flex;
				align-items: center;
				margin-bottom:15px;
				&:before{
					content:"";
					display:inline-block;
					width:37px;
					height:37px;
					margin-right:10px;
				}
				.field-content{
					display:inline-block;
					flex:1;
					line-height:1.4;
				}
			}
			.views-field-city{
				span{
					display:block;
				}
				&:before{
					background:url('../images-source/icon-pin.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-phone{
				&:before{
					background:url('../images-source/icon-phone.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-field-horaires{
				&:before{
					background:url('../images-source/icon-clock.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
		}
	}
}
#block-views-contact-block-2 {
	background:$black;
	color:$white;
	width:50%;
  float: right;
  height:248px;
  margin-top: -248px;
  display:flex;
  flex-wrap: wrap;
  align-content: center;
  h2{
	  padding: 0 7%;
	  width:86%;
	  margin:0;
	  font-size: 15px;
	  text-transform: uppercase;
	  margin-bottom: 15px;
  }
  .view-contact{
	  padding: 0 7%;
	  width:100%;
		align-items: center;
		font-size:13px;
		.views-row {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			.views-field{
				width:47%;
				display:flex;
				align-items: center;
				margin-bottom:15px;
				&:before{
					content:"";
					display:inline-block;
					width:37px;
					height:37px;
					margin-right:10px;
				}
				.field-content{
					display:inline-block;
					flex:1;
					line-height:1.4;
				}
			}
			.views-field-city{
				span{
					display:block;
				}
				&:before{
					background:url('../images-source/icon-pin.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-phone{
				&:before{
					background:url('../images-source/icon-phone.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-field-horaires{
				&:before{
					background:url('../images-source/icon-clock.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
		}
	}
}
#block-views-contact-block-1{
	color:$white;
	width:45%;
	float:left;
  h2{
	  margin:0;
	  font-size: 20px;
	  text-transform: uppercase;
	  margin-bottom: 15px;
  }
  .view-contact{
	  width:100%;
		font-size:13px;
		.views-row {
			.views-field{
				width:100%;
				display:flex;
				align-items: center;
				margin-bottom:15px;
				&:before{
					content:"";
					display:inline-block;
					width:37px;
					height:37px;
					margin-right:10px;
				}
				.field-content{
					display:inline-block;
					flex:1;
					line-height:1.4;
				}
			}
			.views-field-city{
				span{
					display:block;
				}
				&:before{
					background:url('../images-source/icon-pin-dark.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin-dark.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin-dark@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-phone{
				&:before{
					background:url('../images-source/icon-phone-dark.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone-dark.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone-dark@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-field-horaires{
				&:before{
					background:url('../images-source/icon-clock-dark.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock-dark.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock-dark@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
		}
	}
}
#block-views-taxonomy-block-2{
	width:55%;
	float:left;
	h2.block__title{
		color:$white;
		font-size:20px;
		font-weight:700;
		text-transform:uppercase;
		margin:0;
		margin-bottom:15px;
	}
	input,
	textarea{
		background:$black;
		border:none;
		width:100%;
		padding:0.6em 1em;
		color:$white;
	}
	.form-item{
		margin:0;
		margin-bottom:15px;
	}
	.webform-component--type-de-remorques,
	.webform-component--modele,
	.webform-component--nom--prenom,
	.webform-component--e-mail{
		width:48%;
		float: left;
	}
	.webform-component--type-de-remorques,
	.webform-component--nom--prenom{
		margin-right:4%;
	}
	.webform-submit{
		display: inline-block;
		width:auto;
		border-radius: 2px;
		padding: 1em 1.5em;
		text-transform: uppercase;
		letter-spacing: 1px;
		font-size: 12px;
		font-weight: 700;
		border: none;
		appearance: none;
		color: $white;
		transition: background linear .2s;
		margin: 0 0 30px;
		background: $red;
		float: right;
		&:hover, &:focus {
			background: lighten($red, 10%);
			color: $white;
		}
	}
}
p {
  a {
    color: $red;
  }
}
.page-taxonomy-term {
  .views-field-description,
  .taxonomy-term-description {
    max-width: 70%;
    margin: 0 auto;
    color: $white;
    text-align: center;
  }
  .taxonomy-term-description {
    margin: 40px auto;
  }
  &.page-taxonomy-term-6,
  &.page-taxonomy-term-17 {
    .views-field-description,
    .taxonomy-term-description {
      max-width: 70%;
      margin: 20px auto 30px;
      color: $white;
      background: $black;
      padding: 10px 30px 20px;
      text-align: left;

      h3 {
        background: $red;
        font-size: 15px;
        display: inline-block;
        margin-left: -30px;
        padding-left: 45px;
        padding-right: 15px;
      }
      a {
        color: $red;
      }
    }
  }
  &.page-taxonomy-term-6 {
    .view-taxo {
      display: none;
    }
    .views-field-body {
      h2 {
        display: none;
      }
      h3 {
        line-height: 1.4;
      }
    }
  }
}
// Magasin
.page-node-4,
.page-taxonomy-term-5,
.page-taxonomy-term-4 {
	#block-views-contact-block-1{
		color:$white;
		margin:0 auto;
		margin-top:50px;
		background:none;
		float:none;
		height:auto;
		width:80%;
		h2{
			display:none;
		}
		.views-row{
			text-align:center;
			.views-field{
				width:20%;
				text-align: center;
				display:inline-block;
				vertical-align:top;
				span{
					display:block;
					text-align:center;
				}
				&:before{
					display: block;
					margin:0 auto;
					content:"";
					width:37px;
					height:37px;
					margin-bottom:10px;
				}
			}
			.views-field-city{
				span{
					display:block;
				}
				&:before{
					background:url('../images-source/icon-pin-dark.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin-dark.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-pin-dark@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-phone{
				&:before{
					background:url('../images-source/icon-phone-dark.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone-dark.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-phone-dark@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
			.views-field-field-horaires{
				&:before{
					background:url('../images-source/icon-clock-dark.png') no-repeat;
					/* ECRAN RETINA */
					@media only screen and (-webkit-max-device-pixel-ratio: 1.5),
					only screen and (-o-max-device-pixel-ratio: 3/2),
					only screen and (max--moz-device-pixel-ratio: 1.5),
					only screen and (max-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock-dark.png') no-repeat;
					}
					@media only screen and (-webkit-min-device-pixel-ratio: 1.5),
					only screen and (-o-min-device-pixel-ratio: 3/2),
					only screen and (min--moz-device-pixel-ratio: 1.5),
					only screen and (min-device-pixel-ratio: 1.5) {
						background:url('../images-source/icon-clock-dark@2x.png') no-repeat;
						background-size: 37px 37px;
					}
				}
			}
		}
	}
}



// MEtal
.page-taxonomy-term-16,
.page-taxonomy-term-29{
	.item-list .pager{
		display:none;
	}
	.block-views{
		> .view-taxonomy{
			display:flex;
			margin-bottom:60px;
			.view-header{
				width:70%;
				position: relative;
			}
			> .view-content{
				width:30%;
				.views-row{
					padding-left:8%;
				}
			}
		}
	}
	.views-field-title{
		h2{
			color:$red;
			text-transform:uppercase;
			margin-top:0;
			margin-bottom:15px;
		}
	}
	.views-field-body{
		font-size:12px;
		line-height:1.4;
		ul{
			li{
				margin-bottom:10px;
				strong{
					color:$red;
				}
			}
		}
	}
}

//Remorques
.page-taxonomy-term-15{
	.view-display-id-page_3{
		overflow:hidden;
		margin-bottom:60px;
		& > .view-header{
			width:75%;
			float:left;
			.slider-thumb {
				.slick-slider{
					padding:0;
					li{
						background:$white;

						img{
							width:100%;
							box-sizing: padding-box;
						}
						&.slick-current{
							img{
								padding:3px;
							}
						}
					}
				}
			}
		}
		& > .view-content{
			width: 25%;
			float:left;
			.views-row{
				padding-left:8%;
			}
			.views-field-body{
				li{
					text-transform:uppercase;
					font-size:12px;
					font-weight:700;
					line-height: 1.4;
					margin-bottom:10px;
				}
			}
		}
	}
}

.slick-slide {
  img {
    margin: 0 auto;
    max-height: 533px;
    width: auto;
  }
}
//Clotures
.page-taxonomy-term-18{
	.view-display-id-page_3{
		overflow:hidden;
		margin-bottom:60px;
		& > .view-header{
			width:75%;
			float:left;
			.slider-thumb {
				.slick-slider{
					padding:0;
					li{
						background:$white;

						img{
							width:100%;
							box-sizing: padding-box;
						}
						&.slick-current{
							img{
								padding:3px;
							}
						}
					}
				}
			}
		}
		& > .view-content{
			width: 25%;
			float:left;
			.views-row{
				padding-left:8%;
			}
			.views-field-body{
				li{
					text-transform:uppercase;
					font-size:12px;
					font-weight:700;
					line-height: 1.4;
					margin-bottom:10px;
				}
			}
		}
	}
}

// Quincaillerie
.node-type-quincaillerie-outillage {
  .field-name-field-lien {
    width: 25%;
    float: left;
    .field-items {
      padding-left: 5%;
    }
		a{
			display: inline-block;
			border-radius: 2px;
			padding: 1em 0;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			font-weight: 700;
			border: none;
			appearance: none;
			color: $white;
			transition: background linear .2s;
			margin: 0;
			background: $red;
			text-decoration:none;
			font-size:36px;
			letter-spacing: 2px;
			width: 100%;
			text-align: center;
			&:hover, &:focus {
				background: lighten($red, 10%);
				color: $white;
			}
		}
  }
}
.page-taxonomy-term-17{
	.views-field-field-lien {
		&:after{
			content:"ou";
			text-align:center;
			text-transform: uppercase;
			display:block;
			margin:20px 0;
			color:$white;
			font-size:20px;
			letter-spacing:1px;
			font-weight:500;
		}
		a{
			display: inline-block;
			border-radius: 2px;
			padding: 1em 0;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			font-weight: 700;
			border: none;
			appearance: none;
			color: $white;
			transition: background linear .2s;
			margin: 0;
			background: $red;
			text-decoration:none;
			font-size:36px;
			letter-spacing: 2px;
			width: 100%;
			text-align: center;
			&:hover, &:focus {
				background: lighten($red, 10%);
				color: $white;
			}
		}
	}
	.view-display-id-page_4{
		& > .view-header{
			width:75%;
			float:left;
			margin-bottom:60px;
			.slider-thumb {
				.slick-slider{
					padding:0;
					li{
						background:$white;

						img{
							width:100%;
							box-sizing: padding-box;
						}
						&.slick-current{
							img{
								padding:3px;
							}
						}
					}
				}
			}
		}
		& > .view-content{
			width: 25%;
			float:left;
			.views-row{
				padding-left:8%;
			}
			.views-field-body{
				li{
					text-transform:uppercase;
					font-size:12px;
					font-weight:700;
					line-height: 1.4;
					margin-bottom:10px;
				}
			}
		}
	}
	#block-views-contact-block-1{
		width: 100%;
		h2,
		.view-contact{
			padding-left:8%;
		}
		h2 {
			text-align: center;
		}

		.views-row {
			text-align: center;

			.views-field {
				width: 20%;
		    text-align: center;
		    display: inline-block;
		    vertical-align: top;

				&:before {
					display: block;
					margin: 0 auto 10px;
				}
			}
		}
	}
}
.section-atelier{
	.view-atelier-taxonomy{
		.views-row{
			margin-bottom:60px;
			overflow:hidden;
			.views-field-field-images{
				float:left;
				width:75%;
				position: relative;
			}
			.views-field-body{
				width:25%;
				float:right;
				font-size:12px;
				line-height:1.6;
				.field-content{
					padding-left:8%;
				}
				h2{
					color:$red;
					text-transform: uppercase;
					line-height:1.4;
					margin:0;
					margin-bottom:15px;
				}
				h3{
					color:$red;
					font-size:12px;
					text-transform: uppercase;
					font-weight:900;
					margin:0;
					margin-top:10px;
					margin-bottom:5px;
				}
				.button{
					margin:0;
					margin-top:20px;
				}
			}
		}
	}
}
.view-actualit-s{
  &.view-display-id-block_2 {
    .view-content {
      .views-row {
        width: 100%;
        box-shadow: none;
        background: none;

        &:hover {
          box-shadow: none;
        }
        img {
          display: block;
        }
      }
    }
  }
	.view-content{
		display:flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.views-row{
			width:46%;
			background:$black;
			box-shadow: 0px 0px 0px 0px $gray;
			transition:all linear .2s;
      margin-bottom: 8%;
			&:hover{
				box-shadow: 0px 0px 0px 8px $gray;
			}
		}
		.views-field-title{
			h2{
				margin:0;
				padding:5% 5% 0;
				line-height:1.2;
				a{
					text-decoration:none;
					color:$red;
					text-transform: uppercase;
					font-weight:900;
				}
			}
		}
		.views-field-created{
			color:$white;
			font-style: italic;
			padding:0 5%;
			margin-bottom:10px;
			font-size:12px;
		}
		.views-field-body{
			font-size:12px;
			line-height:1.6;
			padding:0 5%;
		}
		.views-field-path{
			padding:0 5% 5%;
			a{
				margin:0;
				margin-top:15px;
			}
		}
	}
}

.node-type-article{
	.field-group-div{
		background:$black;
		max-width: 735px;
		padding:2% 5% 4%;
		margin:-75px auto 0;
		position: relative;
		z-index:90;
		.field-name-title-field{
			h1{
				text-align:left;
				text-transform:uppercase;
				font-size:22px;
				font-weight:900;
				margin:0;
				line-height:1;
				margin-top:15px;
			}
			.published{
				color:$white;
				margin-bottom:15px;
				font-size:12px;
				font-style: italic;
			}
		}
		.field-name-body{
			color:$white;
			font-size:12px;
			line-height:1.6;
			float: none;
			width: 100%;
		}
	}
	.social-share,
	#block-views-actualit-s-block-3 {
		max-width: 735px;
		margin:0 auto;
		ul {
			padding: 0;
			margin: 0;

			li {
				padding: 0;
				margin: 0;
				list-style: none;
			}
		}
		a{
			background:$red;
			display:block;
			padding:1em;
			color:$white;
			text-decoration: none;
			text-transform: uppercase;
			text-align:center;
		}
	}
}
.page-node-30{
	.block-webform{
		width:55%;
		margin-right:5%;
		float:left;
		margin-top:50px;
		h2{
			color:$white;
			text-transform:uppercase;
			margin:0;
		}
		input,
		textarea{
			background:$black;
			border:none;
			width:100%;
			padding:0.6em 1em;
			color:$white;
		}
		.webform-component-textfield,
		.webform-component-email{
			width:40%;
			float:left;
		}
		.webform-component-textfield{
			margin-right:3%;
		}
		.webform-component-textarea{
			clear:both;
		}
		.form-submit{
			display: inline-block;
			width:auto;
			border-radius: 2px;
			padding: 1em 1.5em;
			text-transform: uppercase;
			letter-spacing: 1px;
			font-size: 12px;
			font-weight: 700;
			border: none;
			appearance: none;
			color: $white;
			transition: background linear .2s;
			margin: 0;
			background: $red;
			margin-bottom:100px;
			&:hover, &:focus {
				background: lighten($red, 10%);
				color: $white;
			}
		}
	}
	.view-contact{
		background:$black;
	}
}
.copyright{
	background:#313131;
	.container{
		display:flex;
		justify-content:space-between;
		p{
			margin:0;
			padding:7px 0;
			font-size:11px;
			text-align:right;
			color:$white;
			&:first-child{
				text-align:left;
			}
			a{
				color:$red;
			}
		}
	}
}



#block-views-home-block,
#block-views-home-block-1 {
	margin-top: 60px;
	> .view-home {
		margin-bottom: 8%;
		padding-top: 2%;
		padding-left: 4%;
		position: relative;
		overflow: hidden;
		> .views-content {
			background: $black;
			color: $white;
			width: 50%;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 30;
			> .field-content{
				padding: 30px;
			}
			h2 {
				color: $red;
				text-transform: uppercase;
				font-weight: 900;
				font-size: 30px;
				letter-spacing: 1px;
				padding-left: 15px;
				position: relative;
				&:after {
					display: block;
					width: 46px;
					height: 5px;
					content: "";
					background: $lightgray;
					position: absolute;
					left: 0;
					margin: 10px 0;
				}
			}
			h3{
				background: $red;
				font-size: 15px;
				display: inline-block;
				margin-left: -30px;
				padding-left: 45px;
				padding-right: 15px;
				&:before {
					content: "•";
					display: inline-block;
					margin-right: 5px;
				}
			}
			p{
				padding: 0 15px;
				margin: 0.5em 0;
				strong {
					color: $red;
				}
			}
		}
		> .view-header {
			width: 50%;
		}
	}
}
#block-views-home-block {
  position: relative;
  > .view-home {
    > .view-header {
      position: absolute;
      left: 30px;
      top: 15px;
    }

    > .views-content {
      position: relative;
      margin-left: 50%;
    }
  }
}
#block-views-home-block-1 {
	> .view-home {
		padding-left: 0;
		padding-right: 4%;

		> .views-content {
  		position: relative;
		}
		> .view-header {
			position: absolute;
			right: 4%;
      top: 0;
		}
	}
}

.mobile-menu {
  display: none;
}
.page-user {
  color: $white;
  label {
    color: $white;
  }
}


.view-taxonomy {
  .views-field-field-lien {
    display: none;
  }
}
.node-type-clotures {
  .field-name-body {
    width: 25%;
    float: left;
    color: $white;
    .field-items {
      padding-left: 8%;
    }
  }
  .field-name-field-images {
    width: 75%;
    float: left;

    img {
      width: 100%;
      height: auto;
    }
  }
}
.not-logged-in {
  .views-field-edit-node,
  .views-field-edit-term {
    display: none;
  }
}
.search-advanced {
  display: none;
}
.page-search {
  h2 {
    color: $red;
  }
  p {
    color: $white;
  }

  .search-results {
    padding-left: 0;
    li {
      padding: 20px;
      margin-bottom: 20px;
      &:hover {
        background: rgba($white, 0.2);
      }
      .search-snippet-info {
        padding-left: 0;

        i {
          background: $red;
        }
      }
      .search-info {
        display: none;
      }
      h3 {
        margin-top: 0;
      }
      a {
        color: $white;
      }
    }
  }
}
.view-search-results {
  .views-exposed-form {
    background: $black;
    padding: 20px;
    margin-bottom: 30px;

    .views-exposed-widgets {
      display: flex;
      align-items: center;
      margin: 0;

      .views-exposed-widget {
        float: none;
        padding: 0;

        label {
          display: inline-block;
          color: $white;
          font-size: 16px;
          margin-right: 20px;
        }

        .views-widget {
          display: inline-block;

          input {
            border: none;
            padding: 5px;
          }
        }

        .form-submit {
          margin-top: 0;
          background: $red;
          border: none;
          color: $white;
          padding: 5px;

          &:hover,
          &:focus {
            background: $black;
            color: $white;
          }
        }
      }
    }
  }

  .view-content {
    .views-row {
      padding: 20px;
      margin-bottom: 20px;
      display: flex;
      color: $white;

      .views-field-field-images {
        margin-right: 20px;
      }

      &:hover {
        background: rgba($white, 0.2);
      }
      h2 {
        color: $red;
        margin: 0;
        a {
          color: $red;
          i {
            color: $white;
          }
        }
      }
      p {
        color: $white;
      }
      a {
        color: $white;
      }
      i {
        background: $red;
        font-style: normal;
      }
    }
  }
}

#block-views-actualit-s-block-1 {
  h2 {
    color: $white;
  }
}

.page-taxonomy-term-5,
.page-taxonomy-term-4,
.page-taxonomy-term-16 {
  article,
  .pager {
    display: none;
  }
}
.field-name-field-historique {
  clear: both;
  margin: 30px 0;
  color: #FFF;
}
#block-views-base-block-1 {
  clear: both;
  margin: 0 auto;
  padding: 30px 0;
  color: #FFF;
  max-width: 80%;
  text-align: center;
}

#search-form {
  background: $black;
  padding: 20px;
  .container-inline {
    display: flex;
    align-items: center;
  }
  label {
    display: inline-block;
    color: $white;
    font-size: 16px;
    margin-right: 20px;
  }
  #edit-submit {
    background: $red;
    border: none;
    color: $white;
    margin-left: 10px;
    padding: 5px;

    &:hover,
    &:focus {
      background: $black;
      color: $white;
    }
  }
}
@import 'admin';
