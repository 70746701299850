$black: #000;
$white: #fff;
$red: #d11026;
$gray: #3d3d3d;
$lightgray: #313131;

$museo: "museo-sans",sans-serif;



