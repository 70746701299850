@font-face {
  font-family: 'fontello';
  src: url('../font/fontello.eot?69084163');
  src: url('../font/fontello.eot?69084163#iefix') format('embedded-opentype'),
       url('../font/fontello.woff2?69084163') format('woff2'),
       url('../font/fontello.woff?69084163') format('woff'),
       url('../font/fontello.ttf?69084163') format('truetype'),
       url('../font/fontello.svg?69084163#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media screen and (-webkit-min-device-pixel-ratio:0) {
  @font-face {
    font-family: 'fontello';
    src: url('../font/fontello.svg?69084163#fontello') format('svg');
  }
}
*/

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;

  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-chevron-thin-right:before { content: '\e828'; } /* '' */
.icon-chevron-thin-up:before { content: '\e829'; } /* '' */
.icon-chevron-thin-down:before { content: '\e82a'; } /* '' */
.icon-chevron-thin-left:before { content: '\e82b'; } /* '' */
.icon-chevron-small-right:before { content: '\e82c'; } /* '' */
.icon-chevron-small-up:before { content: '\e82d'; } /* '' */
.icon-chevron-small-down:before { content: '\e82e'; } /* '' */
.icon-chevron-small-left:before { content: '\e82f'; } /* '' */
.icon-chevron-right:before { content: '\e830'; } /* '' */
.icon-chevron-up:before { content: '\e831'; } /* '' */
.icon-chevron-down:before { content: '\e832'; } /* '' */
.icon-chevron-left:before { content: '\e833'; } /* '' */
.icon-flow-cascade:before { content: '\e848'; } /* '' */
.icon-open-book:before { content: '\e855'; } /* '' */
.icon-trash:before { content: '\e860'; } /* '' */
.icon-folder:before { content: '\e869'; } /* '' */
.icon-images:before { content: '\e86c'; } /* '' */
.icon-image:before { content: '\e86e'; } /* '' */
.icon-text:before { content: '\e878'; } /* '' */
.icon-help:before { content: '\e893'; } /* '' */
.icon-login:before { content: '\e8a3'; } /* '' */
.icon-lock-open:before { content: '\e8a4'; } /* '' */
.icon-lock:before { content: '\e8a5'; } /* '' */
.icon-clipboard:before { content: '\e8b3'; } /* '' */
.icon-wallet:before { content: '\e8b6'; } /* '' */
.icon-shopping-basket:before { content: '\e8b7'; } /* '' */
.icon-shopping-bag:before { content: '\e8b8'; } /* '' */
.icon-shopping-cart:before { content: '\e8b9'; } /* '' */
.icon-shop:before { content: '\e8ba'; } /* '' */
.icon-credit-card:before { content: '\e8bb'; } /* '' */
.icon-tv:before { content: '\e8be'; } /* '' */
.icon-eraser:before { content: '\e8cb'; } /* '' */
.icon-pencil:before { content: '\e8cc'; } /* '' */
.icon-hand:before { content: '\e8f2'; } /* '' */
.icon-lifebuoy:before { content: '\e8f5'; } /* '' */
.icon-pin:before { content: '\e8fb'; } /* '' */
.icon-man:before { content: '\e907'; } /* '' */
.icon-megaphone:before { content: '\e90c'; } /* '' */
.icon-camera:before { content: '\e90d'; } /* '' */
.icon-tools:before { content: '\e911'; } /* '' */
.icon-bell:before { content: '\e915'; } /* '' */
.icon-print:before { content: '\e916'; } /* '' */
.icon-home:before { content: '\e91b'; } /* '' */
.icon-typing:before { content: '\e91d'; } /* '' */
.icon-message:before { content: '\e91e'; } /* '' */
.icon-chat:before { content: '\e921'; } /* '' */
.icon-feather:before { content: '\e927'; } /* '' */
.icon-location-pin:before { content: '\e92f'; } /* '' */
.icon-location:before { content: '\e930'; } /* '' */
.icon-old-phone:before { content: '\e938'; } /* '' */
.icon-phone:before { content: '\e93a'; } /* '' */
.icon-thumbs-down:before { content: '\e943'; } /* '' */
.icon-thumbs-up:before { content: '\e944'; } /* '' */
.icon-user:before { content: '\e945'; } /* '' */
.icon-users:before { content: '\e946'; } /* '' */
.icon-instagram:before { content: '\e94a'; } /* '' */
.icon-linkedin:before { content: '\e94b'; } /* '' */
.icon-windows-store:before { content: '\e94f'; } /* '' */
.icon-google-play:before { content: '\e950'; } /* '' */
.icon-app-store:before { content: '\e951'; } /* '' */
.icon-pinterest:before { content: '\e98b'; } /* '' */
.icon-facebook:before { content: '\e990'; } /* '' */
.icon-twitter:before { content: '\e991'; } /* '' */