#block-webform-client-block-29{
	display: none;
}

// ADMIN
$white: #eff3f3; 

$grey: #566472;
$dark-grey: #34495e;

$turquoise: #16a085;

$red: #ff6c60;
$dark-red: #cd3f3d;

ul.tabs.primary {
	position: fixed;
	bottom: 0;
	right: 50px;
  z-index: 300;
  display: flex;
  margin: 0;
  border:none;
	li {
  	list-style: none;
		&:before {
			display: none;
		}
		a {
			background: $grey;
			padding: 0.5em 1em;
	    display: block;
	    margin: 0 1px;
	    color: #FFF;
	    text-decoration: none;
	    font-weight: 100;
	    border:none;
			&:hover,
			&:focus,
			&.active {
  			border:none;
				background: $turquoise;
			}
		}
	}
}


#tools {
  background: $dark-grey;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  .region-tools {
    display: flex;
    justify-content: space-between;
  }
  .block-menu {
    width: 100%;
  }
  .menu {
    padding: 0;
    margin: 0;
    display: flex;
    li {
      list-style: none;
      padding: 0;
      margin: 0;
      position: relative;
      &.first {
        a {
          font-weight: bolder;
        }
      }
      &.last {
        flex: 1;
        text-align: right;
        
        a {
          display: inline-block;
          width: auto;
        }
      }
      a {
        display: block;
        width: 100%;
        box-sizing: border-box;
        color: $white;
        text-decoration: none;
        padding: 0 1.2em;
        text-transform: uppercase;
        font-family: $museo;
        line-height: 65px;
        height: 65px;
        &:before {
          margin-right: 5px;
          font-family: FontAwesome;
        }
        &:hover,
        &:focus {
          background: $turquoise;
        }
        &.fa-power-off {
          background: $red;
          &:hover,  
          &:focus {
            background: $dark-red;
          }
        }
        &.home{
          &:hover,
          &:focus {
            background: none;
            color: $turquoise;
          }
        }
      }
      .menu {
        display: none;
        position: absolute;
        left: 0;
        top: 100%;
        background: $grey;
        min-width: 320px;
        li {
          &.last {
            text-align: left;
            
            a {
              display: block;
              width: 100%;
            }
          }
          a{
            height: 40px;
            line-height: 40px;
            &:hover,
            &:focus {
              background: darken($turquoise, 10%);
            }
          }
          &.first {
            a{
              font-weight: normal;
            }
          }
        }
      }
      &:not(.last, .first):hover {
        > a {
          background: $turquoise;
        }
        .menu {
          display: block;
        }
      }
    }
  }
}

.logged-in {
  padding-top: 65px;
}

.view-taxonomy,
.view-atelier-taxonomy {
  position: relative;
}
.views-field-edit-node,
.views-field-edit-term {
  position: absolute;
  left: 0;
  top: 0;
  background: $turquoise;
  padding-left: 10px;
  color: $white;
  &:before {
    display: none !important;
  }
  
  a {
    padding: 10px;
    color: $white;
    text-decoration: none;
    display: inline-block;
    
    &:before {
      display: none !important;
    }
  }
}

.view-atelier-taxonomy,
.view-actualit-s {
  .views-row {
    position: relative;
  }
}
.page-taxonomy-term-17 {
  #block-views-contact-block-1 {
    padding-bottom: 100px;
    .views-field-edit-node{
      bottom: 0;
      right: 0;
      top: auto;
      left: auto;
    }
  }
}

.tabs {
  display: none;
}